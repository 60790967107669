<template>
  <div
    v-bind="$attrs"
    style="border-radius: 7px; min-width: 120px !important"
    class="settings__info--block flex-grow-0 flex-shrink-1"
  >
    <input
      ref="fileInp"
      class="d-none"
      multiple
      type="file"
      accept="image/*"
      :disabled="loading"
      :title="loaded ? 'Cкриншот выбран' : 'Загрузите скриншот'"
      @change="
        (event) => {
          if (handleUpload) {
            handleFile(event.target.files);
          } else {
            $emit('input', event.target.files);
          }
        }
      "
    />
    <b-button
      :style="'min-width: 120px !important;' + buttonStyle"
      class="ph-36"
      :disabled="loading"
      @click="$refs.fileInp.click()"
      :variant="loaded ? 'outline-default' : 'primary'"
    >
      <div class="d-flex align-items-center" :class="{ 'justify-content-center': !loaded }">
        <b-spinner
          style="opacity: 0.5; color: #d5dfe4; width: 17px; height: 17px"
          class="mr-2 d-inline-block"
          v-if="loading"
        ></b-spinner>
        <img
          style="width: 17px; height: 17px"
          class="d-inline-block mr-2"
          v-if="loaded"
          src="@main/assets/img/svg/done_icon.svg"
          alt=""
        />
        <p :style="{ color: loaded ? 'black' : 'white' }" class="text-center">
          {{ loaded ? loadedText || 'Загружено' : loading ? 'Загрузка' : 'Загрузить' }}
        </p>
      </div>
    </b-button>
  </div>
</template>

<script>
import apiService from '@main/api/index';
import { mapMutations } from 'vuex';

export default {
  props: {
    value: [Object, File, Array],
    btnClass: String,
    accepted: Boolean,
    loadedText: String,
    buttonStyle: String,
    handleUpload: Boolean,
    uploadUrl: String,
  },
  computed: {
    loaded() {
      return this.value && this.value.length;
    },
  },
  data: () => ({
    loading: false,
  }),
  methods: {
    handleFile(files) {
      if (!files.length) {
        this.showMessage({ title: 'Файлы не загружены, выберите файлы' });
      }
      this.loading = true;
      const formData = new FormData();
      for (let k = 0; k < 4; k += 1) formData.append('files[]', files[k]);
      apiService
        .post(this.uploadUrl, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => {
          console.log(res);
          this.$emit('input', res);
        })
        .catch((error) => {
          this.showMessage({ title: 'Ошибка при загрузке файла! Попробуйте другой файл' });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.btn__uploaded::before {
  content: '✔';
  position: absolute;
  color: #ffffff;
  background: #6ac259;
  border-radius: 50%;
  top: 24.3%;
  vertical-align: middle;
  text-align: center;
  width: 1.3rem;
  height: 1.3rem;
  margin-left: -23px;
}
.btn__uploaded {
  padding-left: 33px !important;
  width: 120px !important;
}
.file-button {
  height: 36px !important;
  width: 100px;
  border-radius: 5px !important;
}
</style>
